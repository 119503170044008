import './header.scss';
import frFlag from "../../assets/images/icons/fr.svg";
import enFlag from "../../assets/images/icons/en.svg";
import iconNotif from "../../assets/images/icons/icon-notif.svg";
import iconDanger from "../../assets/images/icons/icon-danger-color.svg";
import iconCheck from "../../assets/images/icons/icon-check.svg";
import defaultAvatar from "../../assets/images/default-profile.svg";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { StoreContext } from '../../store/store';
import { MercureContext } from '../../context/MercureProvider';


// Services
import { logOut, getListNotification } from "../../store/index.service";
import { use } from 'react';

export const Header = () => {
    const { lang, setLang, setLoggedIn, user = {}, isTrialOffer, workspace } = useContext(StoreContext);
    const [listNotif, setListNotif] = useState([]);
    const [dropdownUser, setDropdownUser] = useState(false);
    const [dropdownLang, setDropdownLang] = useState(false);
    const [dropdownNotif, setDropdownNotif] = useState(false);
    const userDropdownRef = useRef(null);
    const langDropdownRef = useRef(null);
    const notifDropdownRef = useRef(null);
    const { subscribeToTopic, nbNotif, setNbNotif } = useContext(MercureContext);

    const handleClickOutside = (event) => {
        if (userDropdownRef.current && !userDropdownRef.current.contains(event.target))
            setDropdownUser(false);
        if (langDropdownRef.current && !langDropdownRef.current.contains(event.target))
            setDropdownLang(false);
        if (notifDropdownRef.current && !notifDropdownRef.current.contains(event.target))
            setDropdownNotif(false);
    };

    const logout = () => {
        logOut();
        setLoggedIn(false);
    }

    const handleGetDataNotif = useCallback(() => {
        getListNotification().then((res) => {
            setListNotif(res.data);
            setNbNotif(res.data.length);
            document.title = `(${res.data.length}) Notification`;
        });
    }, [setListNotif, setNbNotif]);

    useEffect(() => {
        subscribeToTopic(`/hub/${workspace.workspaceId}`, handleGetDataNotif);
    }, [subscribeToTopic]);

    useEffect(() => {
        getListNotification().then((res) => {
            setListNotif(res.data);
            setNbNotif(res.data.length);
        });
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLanguageChange = (lang) => {
        setLang(lang);
        setDropdownLang(false);
    };

    return (
        <header className={isTrialOffer ? 'isTrial' : ''}>
            <div className="header-container">

                <Link to="/compte/abonnement" className="btn"><span>Améliorer mon plan</span></Link>

                <div className="user" ref={userDropdownRef} onClick={() => setDropdownUser(!dropdownUser)}>
                    <img src={defaultAvatar} alt="user" />
                    <p>{user.firstName} {user.lastName} </p>
                    <FontAwesomeIcon icon={faChevronDown} />

                    {dropdownUser &&
                        <div className="user-nav">
                            <Link to="/compte/profile">Profil</Link>
                            <Link to="/compte/abonnement">Abonnement</Link>
                            <Link onClick={() => logout()}>Déconnexion</Link>
                        </div>
                    }
                </div>

                <div className="lang" ref={langDropdownRef} onClick={() => setDropdownLang(!dropdownLang)}>
                    <img src={lang === "fr" ? frFlag : enFlag} alt="language" />

                    {dropdownLang &&
                        <div className="lang-nav">
                            <div onClick={() => handleLanguageChange("fr")}>
                                <img src={frFlag} alt="French" />
                            </div>
                            <div onClick={() => handleLanguageChange("en")}>
                                <img src={enFlag} alt="English" />
                            </div>
                        </div>
                    }
                </div>

                <div className="notification">
                    <div className="notification-icon" onClick={() => setDropdownNotif(!dropdownNotif)}>
                        <img src={iconNotif} alt="notification" />
                        {nbNotif > 0 && <span className="notif-badge">{nbNotif}</span>}
                    </div>

                    {dropdownNotif &&
                        <div className="notification-nav" ref={notifDropdownRef}>
                            <h3>Notification</h3>

                            {listNotif.length === 0 &&
                                <div className="notif-item">
                                    <img src={iconDanger} alt="notification" />
                                    <p>Aucune notification</p>
                                </div>
                            } 

                            {listNotif.length > 0 && listNotif.map((notif, index) => (
                                <div className="notif-item" key={index}>
                                    {notif.type === "error" && <img src={iconDanger} alt="notification" />}
                                    {notif.type === "success" && <img src={iconCheck} alt="notification" />}
                                    <p>{notif.message}</p>
                                </div>
                            ))}

                        </div>
                    }

                </div>

            </div>
        </header>
    );
};
