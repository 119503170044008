import "./sidebar.scss";
import { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getListConversation, getCountMessageNotRead } from '../../../store/index.service';
import { MessagingContext } from '../../../context/MessagingProvider';
import { truncateText } from '../../../utils/helpers';
import { StoreContext } from "../../../store/store";
import defaultAvatar from "../../../assets/images/default-avatar.webp";

export const Sidebar = () => {
	const [listConversation, setListConversation] = useState({loading: true, data: []});
	const { idConversation, setIdConversation, setInfoUser, setPhoneUser, setBlacklistUser, setIdContact } = useContext(MessagingContext);
	const { workspace, setNbMessageNotRead } = useContext(StoreContext);
	const [searchValue, setSearchValue] = useState('');
	
	useEffect(() => {
		handleGetData();

		const url = new URL('https://mercure.whakup.com/.well-known/mercure');
		let topic = "merc/" + workspace.workspaceId + "/messaging";
		url.searchParams.append('topic', topic);
		const eventSource = new EventSource(url);

		eventSource.onmessage = () => {
			handleGetData();
			getCountMessageNotRead().then(res => {
				setNbMessageNotRead(res);
			});
		};

		return () => {
			eventSource.close();
		};
			
	}, [workspace]);

	const handleGetData = () => {
		getListConversation().then(res => {
			setListConversation({loading: false, data: res ?? []});
		});
	}

	const handleSelectConversation = (item) => {
		const infoUser = `${item.contact.firstName || ""}${item.contact.lastName ? ` ${item.contact.lastName}` : ""}`;
		setIdConversation(item.id);
		if (infoUser !== "") {
			setInfoUser(infoUser);
			setPhoneUser(item.contact.phone);
			setBlacklistUser(item.blacklisted);
			setIdContact(item.contact.id);
		} else {
			setPhoneUser(item.contact.phone);
			setBlacklistUser(item.blacklisted);
			setIdContact(item.contact.id);
		}
		
	}

	const filteredConversations = listConversation.data.filter(item =>
		(item.contact.firstName?.toLowerCase().includes(searchValue.toLowerCase()) || false) ||
		item.contact.phone.toLowerCase().includes(searchValue.toLowerCase())
	  );
	
	function formatDate(dateStr) {
		const date = new Date(dateStr);
		const now = new Date();
		const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
		
		// Si la date est aujourd'hui, afficher l'heure uniquement
		if (date.toDateString() === now.toDateString()) {
			return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
		}
	
		// Formater en 'Hier' si la date est celle d'hier
		if (date.toDateString() === yesterday.toDateString()) {
			return 'Hier';
		}
	
		// Sinon, formater en 'JJ/MM/AAAA'
		return date.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
	}
	
	return (
		<aside className="sidebar">

			<div className="header">
				<div className="user">
					<img src={defaultAvatar} alt="user" />
					<span>Whakup</span>
				</div>
				<div className="listAction"></div>
			</div>

			<div className="search">
				<div className="searchWrapper">
					<FontAwesomeIcon icon={faSearch} />
					<input type="text" placeholder="Search your contact" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
				</div>
			</div>

			<div className="listContact">
				{filteredConversations.map((item, index) => (
					<div 
						key={index}
						className={`contactWrapper ${idConversation === item.id ? 'active' : ''}`} // Appliquez conditionnellement la classe 'active'
						onClick={() => handleSelectConversation(item)}
					>
						<div className="avatar">
							<img src={defaultAvatar} alt="user" />
						</div>
						<div className="contentContact">
							<div className="topContent">
								<span className={item.unreadMessagesCount > 0 ? 'name unread' : 'name'}>
								{`${item.contact.firstName || ""}${item.contact.lastName ? ` ${item.contact.lastName}` : ""}`}
								</span>
								<span className="time">
									{item.lastMessageDate != null && formatDate(item.lastMessageDate.date)}
								</span>
							</div>
							<div className="bottomContent">
								{item.lastMessageContent != null && 
									<span className="message">
										<span className={item.unreadMessagesCount > 0 ? 'message unread' : 'message'}>
											{truncateText(item.lastMessageContent)}
										</span>
									</span>
								}
								
								{item.unreadMessagesCount > 0 && (
									<span className="badge">{item.unreadMessagesCount}</span>
								)}
							</div>
						</div>
					</div>
				))}
			</div>

		</aside>
	);
};

