import "./conversation.scss";
import { useEffect, useState, useContext } from "react";
import { getOneConversation, sendMessage, updateContactBlacklist, sendReadMessage, sendUnReadMessage } from '../../../store/index.service';
import { MessagingContext } from '../../../context/MessagingProvider';
import { EmojiTray } from "./EmojiTray/EmojiTray";
import { ChatInput } from "./ChatInput/ChatInput";
import { ContentTypeTemplate } from "./ContentTypeTemplate/ContentTypeTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { StoreContext } from "../../../store/store";
import defaultAvatar from "../../../assets/images/default-avatar.webp";
import { MercureContext } from '../../../context/MercureProvider';

export const Conversation = () => {
	const { idConversation, infoUser, phoneUser, setBlacklistUser, blacklistUser, idContact } = useContext(MessagingContext);
	const [oneConversation, setOneConversation] = useState({loading: true, data: {}});
	const [infoContact, setInfoContact] = useState({loading: true, data: []});
	const [showAttach, setShowAttach] = useState(false);
	const [showEmojis, setShowEmojis] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const { workspace } = useContext(StoreContext);
	const lastMessage = oneConversation.data[Object.keys(oneConversation.data)[Object.keys(oneConversation.data).length - 1]];
	const { subscribeToTopic } = useContext(MercureContext);


	let showNotification = false;
	if (lastMessage) {
		const lastMessageOfDay = lastMessage && lastMessage.length > 0 ? lastMessage[lastMessage.length - 1] : null;
	  	if (lastMessageOfDay) {
			showNotification = isMessageOlderThan24Hours(lastMessageOfDay.sendAt.date);
	  	}
	}

	useEffect(() => {
		handleGetData();
		let topic = "merc/" + workspace.workspaceId + "/messaging" + "/" + idConversation;
        subscribeToTopic(topic, handleGetData);
    }, [subscribeToTopic, idConversation]);

	const handleGetData = () => {
		console.log("🔄 idConversation changé :", idConversation);
		if (!idConversation)
			return;
	
		getOneConversation(idConversation).then(res => {
			setOneConversation({loading: false, data: res.conversation ?? {}});
			setInfoContact({loading: false, data: res ?? []});
			setTimeout(() => {
                scrollToBottom();
            }, 300);

			sendReadMessage(idConversation);
		});
	}
	
	// Fonction pour vérifier si un message est plus vieux que 24 heures
	function isMessageOlderThan24Hours(messageDate) {
		const messageDateTime = new Date(messageDate).getTime();
		const now = Date.now();
		const hoursDiff = (now - messageDateTime) / (1000 * 60 * 60);
		return hoursDiff > 24;
	}
	
	const handleSendMessage = () => {

		if (showNotification) {
			// Vous pouvez ici afficher un message d'erreur ou une alerte à l'utilisateur
			alert("Le dernier message a été envoyé il y a plus de 24 heures. Veuillez envoyer une campagne pour rentrer en communication avec cet utilisateur. ");
			return; // Empêcher l'envoi du message
		}

		sendMessage(idConversation, newMessage).then(res => {
			handleGetData();
			setNewMessage('');
			setTimeout(() => {
                scrollToBottom();
            }, 100);
		});
	};

    const scrollToBottom = () => {
        const chat = document.querySelector('.conversationContent');
        chat.scrollTop = chat.scrollHeight;
    };

	const handleBlacklist = (id) => {
		setBlacklistUser(!blacklistUser);
		updateContactBlacklist(id).then(() => {
			handleGetData();
		});
	};

	// Fonction pour vérifier si une date est aujourd'hui
	const isToday = (date) => {
		const today = new Date();
		return date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear();
	}

	return (
		<div className="conversation">
			<div className="chatBody">

				<div className="chatBackground"></div>

					{idContact ? (
						<div className="header">
							<div className="infoUser">
								<div className="avatar">
									<img src={defaultAvatar} alt="user" />
								</div>
								<div className="contentInfo">
									<span className="name">{infoUser ?? 'Contact'}</span>
									<span className="status">{phoneUser}</span>
								</div>
							</div>
							<div className="action">
								<div className="blacklist">
									<p>Blacklisté</p>
									<label className="switch">
										<input type="checkbox" onChange={() => handleBlacklist(idContact)} checked={blacklistUser} />
										<span className="slider round"></span>
									</label>
								</div>

								<div className="unread-message">
									<button onClick={() => sendUnReadMessage(idConversation)}>Marqué comme non lu</button>
								</div>
							</div>
						</div>
					) : (
						<div className="header">
							<div className="contentInfo">
								<span className="name">Sélectionnez un contact</span>
							</div>
						</div>
					)}
			
			
				<div className="conversationContent">

					<div className="groupMessage">
						{oneConversation && Object.entries(oneConversation.data).map(([date, messages], index) => (
							<>
								<div className="messageDay">
									<p>{isToday(new Date(date)) ? "Aujourd'hui " : date}</p>
								</div>
								{messages && messages.map((item, idx) => (
									<>
										{item.typeContent === 'template' && (
											<div className={`message ${item.typeSender === 'user' ? 'receiver' : 'sender'}`}>
												
												{/*<ContentTypeTemplate content={item.dataTemplate} />*/}
												
												<span className="filter"></span>
												<span className="time">
													{new Date(item.sendAt.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
													{item.typeSender === 'user' && 
														<FontAwesomeIcon icon={faCheck} className={item.check ? 'check' : ''} />
													}
												</span>
											</div>
										)}


										{item.typeContent === 'text' && (
											<p className={`message ${item.typeSender === 'user' ? 'receiver' : 'sender'}`}>
												<span className="messageContent">{item.content}</span>
												<span className="filter"></span>
												<span className="time">
													{new Date(item.sendAt.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
													{item.typeSender === 'user' && 
														<FontAwesomeIcon icon={faCheck} className={item.check ? 'check' : ''} />
													}
												</span>
											</p>
										)}
										{item.typeContent === 'image' && (
											<div className={`message typeImage ${item.typeSender === 'user' ? 'receiver' : 'sender'}`}>
												<img src={item.media[0].url} alt="user" />
												<span className={item.media[0].name ? 'time nameTime' : 'time'}>
													{new Date(item.sendAt.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
													{item.typeSender === 'user' && 
														<FontAwesomeIcon icon={faCheck} className={item.check ? 'check' : ''} />
													}
												</span>
												{item.media[0].name && <p>{item.media[0].name}</p>}
											</div>
										)}

										{item.typeContent === 'video' && (
											<div className={`message ${item.typeSender === 'user' ? 'receiver' : 'sender'}`}>
												<video controls>
													<source src={item.media[0].url} type="video/mp4" />
												</video>
											</div>
										)}

										{item.typeContent === 'audio' && (
											<div className={`message ${item.typeSender === 'user' ? 'receiver' : 'sender'}`}>
												<audio controls>
													<source src={item.media[0].url} type="audio/ogg" />
												</audio>
											</div>
										)}

										{item.typeContent === 'file' && (
											<div className={`message ${item.typeSender === 'user' ? 'receiver' : 'sender'}`}>
												<a href={item.media[0].url} download>Download</a>
											</div>
										)}
									</>
								))}
							</>
						))}
						{showNotification && (
							<div className="notification">
								Le dernier message a été envoyé il y a plus de 24 heures. Veuillez envoyer une campagne pour rentrer en communication avec cet utilisateur. 
							</div>
						)}
					</div>

				</div>
		
				<div className="chat__footer">
					<EmojiTray showEmojis={showEmojis} />
					<ChatInput
						showEmojis={showEmojis}
						setShowEmojis={setShowEmojis}
						showAttach={showAttach}
						setShowAttach={setShowAttach}
						newMessage={newMessage}
						setNewMessage={setNewMessage}
						submitNewMessage={handleSendMessage}
					/>
				</div>

			</div>
		</div>
	);
};

