import './meta.scss';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";
import { FacebookSDK } from '../../../components/Facebook/FacebookSDK';
import { FacebookLoginButton } from '../../../components/Facebook/FacebookLoginButton';
import { getCheckMetaConnected } from '../../../store/index.service';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Toastify } from '../../../components/UI/Toastify/Toastify';

import cardStep1 from '../../../assets/images/card-step-1.png';
import cardStep2 from '../../../assets/images/card-step-2.png';
import cardStep3 from '../../../assets/images/card-step-3.png';
import cardStep4 from '../../../assets/images/card-step-4.png';

export const Meta = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const steps = ['1', '2', '3', '4', '5'];
    const [metaOnboardingFinished, setMetaOnboardingFinished] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        getCheckMetaConnected().then(res => {
            if (res === true)
                setIsConnected(true);
            else
                setIsConnected(false);
        });

        const sessionInfoListener = (event) => {
            try {
                const data = JSON.parse(event.data);

                if (data.type === 'WA_EMBEDDED_SIGNUP' && data.event === 'FINISH') {
                    setMetaOnboardingFinished(true);
                    Toastify('metaConnected', 'success');
                }
            } catch {
                return;
            }
        };

        window.addEventListener('message', sessionInfoListener);

        return () => {
            window.removeEventListener('message', sessionInfoListener);
        };


    }, []);

    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <div className="integration">
            <NavigationAccount active="meta" />

            {isConnected &&
                <div className="connected background-white">
                    <div className="content">
                        <h1>Vous êtes connecté à Meta</h1>
                        <p>Vous avez déjà connecté votre compte Meta à Whakup. Vous pouvez maintenant commencer à utiliser les fonctionnalités de Meta.</p>
                    </div>
                </div>
            }
            {!isConnected &&
                <div className="noconnected background-white">
                    <FacebookSDK />
                    <h1>Connexion avec meta</h1>

                    <div className="container-integration">
                        <div className="container-meta">
                            <div className="stepper">
                                <Stepper activeStep={currentStep - 1} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel></StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>

                            <div className="content">
                                {currentStep === 1 && 
                                    <div>
                                        <div className="contentStepper">
                                            <p>Vous devrez vous authentifier auprès de Metas (Facebook/Whatsapp). Nous allons vous guider étape par étape.</p>
                                            <ul>
                                                <li>Vous devrez vous connecter à votre compte Facebook et avoir accès au Facebook Business Manager de votre entreprise.</li>
                                                <li>Si votre entreprise ne dispose pas d'un compte Facebook (Meta)  Business, vous devez en créer un => <a target="_blank" href="https://www.facebook.com/">Créer un compte Facebook</a> </li>
                                            </ul>
                                        </div>

                                        <div className="footerStepper">
                                            <div></div>
                                            <div className="btnNext" onClick={() => setCurrentStep(2)}>Suivant</div>
                                        </div>
                                    </div>
                                }

                                {currentStep === 2 &&                             
                                    <div>
                                        <div className="contentStepper">
                                            <p>Un numéro de téléphone, devra être associé à votre canal WhatsApp :</p>
                                            <ul>
                                                <li>Votre numéro de téléphone doit appartenir à votre entreprise - nous ne vous conseillons pas d'utiliser votre numéro personnel.</li>
                                                <li>Si avez besoin de créer un nouveau numéro, nous vous conseillons d'en créer un sur la solution <Link to="https://www.onoff.app/fr/" target="_blank">https://www.onoff.app/fr/</Link> </li>
                                                <li>Vous pouvez utiliser un numéro de téléphone qui est déjà enregistré avec WhatsApp Messenger ou l'application WhatsApp Business, mais vous devrez d'abord supprimer WhatsApp Messenger en suivant ces étapes </li>
                                            </ul>
                                        </div>

                                        <div className="footerStepper">
                                            <div className="btnPrev" onClick={() => setCurrentStep(1)}>Précédent</div>
                                            <div className="btnNext" onClick={() => setCurrentStep(3)}>Suivant</div>
                                        </div>
                                    </div>
                                }

                                {currentStep === 3 && 
                                    <div>
                                        <div className="contentStepper">
                                            <p>Vous devrez fournir les informations suivantes pour créer votre canal WhatsApp Business, assurez-vous de les avoir à portée de main :</p>

                                            <ul>
                                                <li>Le nom d'affichage de votre marque, tel qu'il sera indiqué aux clients sur le chat</li>
                                                <li>Le nom légal de votre entreprise</li>
                                                <li>L'adresse officielle de votre entreprise</li>
                                            </ul>

                                            <p>Vous allez être dirigé vers Facebook Business Manager. <br />Finalisez toutes les étapes pour pouvoir lier votre WhatsApp Business à Whakup.</p>

                                            <FacebookLoginButton />
                                        </div>

                                        <div className="footerStepper">
                                            <div className="btnPrev" onClick={() => setCurrentStep(2)}>Précédent</div>
                                            <div className={`btnNext ${metaOnboardingFinished ? '' : 'disabled'}`} onClick={() => metaOnboardingFinished && setCurrentStep(4)}>Suivant</div>
                                        </div>
                                    </div>
                                }

                                {currentStep === 4 && 
                                    <div>
                                        <div className="contentStepper">
                                            <p>Pour pouvoir envoyer des messages sur WhatsApp, vous devez avoir enregistré votre carte bancaire dans Meta (Facebook).</p>
                                            <Link to="https://business.facebook.com/settings/whatsapp-business-accounts" target="_blank">Enregistrez votre carte bancaire dans Meta</Link>

                                            <div className="card">
                                                <p><b>Pour ajouter votre carte bancaire à Meta (Facebook/WhatsApp), suivez ces étapes simples :</b></p>
                                                <p>Étape 1 : Connectez-vous à votre compte Facebook.</p>
                                                <p>Étape 2 : Accédez au Facebook Business Manager de votre entreprise (via le lien fournit)</p>
                                                <p>Étape 3 : Dans les paramètres de paiement, sélectionnez l'option pour ajouter une nouvelle carte bancaire.</p>
                                                <p>Étape 4 : Saisissez les détails de votre carte, y compris le numéro, la date d'expiration et le code de sécurité.</p>
                                                <p>Étape 5 : Validez votre ajout et assurez-vous que la carte est bien enregistrée pour les paiements.</p>
                                                <p>Ces captures d'écran vous guideront à travers chaque étape essentielle.</p>
                                                <img src={cardStep1} alt="card" />
                                                <img src={cardStep2} alt="card" />
                                                <img src={cardStep3} alt="card" />
                                                <img src={cardStep4} alt="card" />
                                            </div>
                                        </div>

                                        <div className="footerStepper">
                                            <div className="btnPrev" onClick={() => setCurrentStep(3)}>Précédent</div>
                                            <div className="btnNext" onClick={() => setCurrentStep(5)}>Suivant</div>
                                        </div>
                                    </div>
                                }

                                {currentStep === 5 && 
                                    <div>
                                        <div className="contentStepper">
                                            <p>Vous pouvez désormais tester Whakup en version d'essai.</p>
                                            <p>Nous vous conseillons d'enregistrer dès maintenant votre carte bancaire sur Whakup. Ce qui est fait n'est plus à faire 🙂. Vous ne serez pas prélevé avant la validation d'un forfait après votre période d'essai.</p>
                                        </div>

                                        <div className="footerStepper">
                                            <div className="btnPrev" onClick={() => setCurrentStep(4)}>Précédent</div>
                                            <div className="btnNext" onClick={() => refreshPage()} >Terminé</div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="tuto-loom">
                            <iframe 
                                src="https://www.loom.com/embed/c1e31f27911f4649871cf4a45ab0013a?sid=48280621-6755-4773-8d39-228c8129cf07" 
                                frameborder="0" 
                                webkitallowfullscreen 
                                mozallowfullscreen 
                                allowfullscreen
                                height={300}
                                width={500}
                            ></iframe>
                        </div>
                    </div>
                    

                </div>
            }
        </div>
    )
}