import { useMemo } from "react";
import styles from "./MetaStatus.module.scss";

export const MetaStatus = ({ variant = null }) => {
    const variantStyle = useMemo(() => {
        switch (variant) {
            case 'green':
                return { color: styles.greenMetaStatus, text: "Bon" };
            case 'orange':
                return { color: styles.orangeMetaStatus, text: "Moyen" };
            case 'red':
                return { color: styles.redMetaStatus, text: "Mauvais" };
            default:
                return { color: styles.unknownMetaStatus, text: "Inconnu" };
        }
    }, [variant]);

    return (
        <span className={styles.metaStatus}>
            <svg className={styles.statusRoundIconMeta} xmlns="http://www.w3.org/2000/svg">
                <circle className={variantStyle.color} cx="8" cy="8" r="8" />
            </svg>
            <p className={styles.metaStatusText}>Meta : {variantStyle.text}</p>
        </span>
    );
};
