import './faq.scss';
import { useState, useEffect, useCallback  } from "react";
import { getListFaqCategory, createFaqSearch } from '../../store/index.service';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DOMPurify from 'dompurify';

export const Faq = () => {
    const [listFaqCategory, setListFaqCategory] = useState({loading: true, data: []});
    const [selectedFaq, setSelectedFaq] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeCategoryId, setActiveCategoryId] = useState(0);
    const { t } = useTranslation();

    const slugify = (text) => 
        text.toString().toLowerCase()
        .normalize('NFD') // Sépare les accents des lettres
        .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques (accents)
        .replace(/\s+/g, '-') // Remplace les espaces par des tirets
        .replace(/[^\w\-]+/g, '') // Supprime tous les caractères non alphanumériques
        .replace(/\-\-+/g, '-') // Remplace les tirets multiples par un seul tiret
        .replace(/^-+/, '') // Coupe les tirets du début
        .replace(/-+$/, ''); // Coupe les tirets de la fin
  
    useEffect(() => {
        handleGetData();
    }, []);

    useEffect(() => {
        if (!listFaqCategory.loading && filteredCategories.length > 0) {
            const currentCategoryExists = filteredCategories.some(category => category.id === activeCategoryId);
    
            if (!currentCategoryExists) {
                const firstCategory = filteredCategories[0];
                setActiveCategoryId(firstCategory.id);
                setSelectedFaq(firstCategory.faq);
            }
        }
    }, [searchTerm, activeCategoryId, listFaqCategory.loading]);

    useEffect(() => {
        const hash = window.location.hash.replace('#', '');

        const categoryToSelect = listFaqCategory.data.find(category => {
            const categorySlug = slugify(category.title);
            return categorySlug === hash;
        });
    
        if (categoryToSelect) {
            setActiveCategoryId(categoryToSelect.id);
            setSelectedFaq(categoryToSelect.faq);
        }
    }, [listFaqCategory.data]);
    

    const debounce = (func, delay) => {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleGetData = () => {
        getListFaqCategory().then(res => {
            setListFaqCategory({loading: false, data: res ?? []});
            if (res && res.length > 0) {
                setActiveCategoryId(res[0].id);
                setSelectedFaq(res[0].faq);
            }
        });
    }

    const handleFaqClick = (id, faq) => {
        setActiveCategoryId(id);
        setSelectedFaq(faq);
    }

    const debouncedSearch = useCallback(debounce((query) => {
        if (query.length >= 3) {
            let data = { content: query };
            createFaqSearch(data);
        }
    }, 500), []);

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        debouncedSearch(value);
    };

    const filteredCategories = listFaqCategory.data.filter(category => {
        const faqsFiltered = category.faq.filter(faq =>
            faq.titleFr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            faq.contentFr.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return faqsFiltered.length > 0;
    });

    const filteredFaqs = searchTerm
        ? selectedFaq.filter(faq => 
            faq.titleFr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            faq.contentFr.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : selectedFaq;

    const questionFaq = (faqs) => {
        if(!faqs || faqs.length === 0) return (<p>{t('page_faq_no_result')}</p>);
        
        return faqs.map((item, index) => (
            <Accordion key={index}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                >
                    <Typography>{item.titleFr}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="content-detail" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.contentFr) }}></div>
                </AccordionDetails>
            </Accordion>
        ));


    }
    
    return (
        <div className="faq">

            <div className="help background-white">
                <h1>{t('faq.title')}</h1>
                <p></p>
                <div className="searchBar">
                    <FontAwesomeIcon icon={faSearch} />
                    <input type="text" placeholder={t('faq.search_placeholder')} onChange={handleSearchChange}  />
                </div>
            </div>

            <div className="faq-content">

                <div className="faq-category">
                    <ul>
                        {filteredCategories.map((item, index) => (
                            <li key={index} onClick={() => handleFaqClick(item.id, item.faq)} className={activeCategoryId === item.id ? 'active' : ''}>
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="faq-list">
                    {questionFaq(filteredFaqs)}
                </div>

            </div>

        </div>
    )
}