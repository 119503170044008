import api from '../api';

export const getUserConnected = async () => {
    return(await api.get('/api/user/connected'));
}

export const getOneUser = async () => {
    const result = await api.get('/api/user' , {});
    return(result.data);
}

export const updateUser = (data) => api.put('/api/user', data)

export const deleteUser = (data) => api.delete('/api/user', data)

export const getOneUserByToken = async () => {
    const result = await api.get('/api/tokenn/user' , {});
    return(result.data);
}