import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Services
import {
    createCampaign,
    getListTemplate,
    getListGroups,
} from '../../store/index.service';

// Store
import { StoreContext } from '../../store/store';

// Components
import { FormInputText } from "../../components/UI/Form/FormInputText";
import { FormButtonSubmit } from "../../components/UI/Form/FormButtonSubmit";
import { FormAutocomplete } from "../../components/UI/Form/FormAutocomplete";
import { WhatsAppExampleCampaign } from "../../components/WhatsAppExample/WhatsAppExampleCampaign";
import { ConfirmCampaign } from '../../components/UI/Popup/ConfirmCampaign';
import { UpgradePlan } from "../../components/UI/Popup/UpgradePlan";
import { Popup } from "../../components/UI/Popup/Popup";
import { Toastify } from "../../components/UI/Toastify/Toastify";
import { ProgressBar } from '../../components/UI/ProgressBar/ProgressBar';

// Assets
import iconPremium from "../../assets/images/icons/icon-premium.svg";
import trophy from '../../assets/images/icons/trophy.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

// Styles
import './campaign.scss';

export const CreateCampaign = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { subscription, workspace } = useContext(StoreContext);
    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            sendProgrammation: { id: 2, title: "Immédiat" }
        }
    });
    const [loading, setLoading] = useState(false);
    const [setErrorMsg] = useState("");
    const [listTemplate, setListTemplate] = useState([]);
    const [listGroups, setListGroups] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [showPopupConfirmCapaign, setShowPopupConfirmCapaign] = useState({ show: false });
    const [showPopupUpgradePlan, setShowPopupUpgradePlan] = useState({ show: false });
    const [selectedSend, setSelectedSend] = useState("Immédiat");

    const onSubmit = async (data, status) => {
        setLoading(true);
        data.status = status;

        try {
            await createCampaign(data);
            Toastify("createCampaign", "success", { name: data.title });
            navigate('/campagnes');
        } catch (err) {
            setLoading(false);

            if (err.response.status === 409) {
                setErrorMsg(err.response.data.result);
                Toastify("error", "error");
            } else if (err.response.status === 403) {
                setShowPopupUpgradePlan({ show: true });
            } else {
                Toastify("error", "error");
                setErrorMsg("Une erreur est survenue");
            }
        }
    };

    useEffect(() => {
        const paramsData = {
            status: true
        };

        getListTemplate(paramsData).then((result) => {
            setListTemplate(result);
        });

        getListGroups().then((result) => {
            setListGroups(result);
        });
    }, []);

    const handleSelectProgramChange = (selectedOption) => {
        if (selectedOption.title === "Programmé") {
            setSelectedSend("Programmé");
        } else {
            setSelectedSend("Immédiat");
        }
    };

    const handleSaveAsDraft = (data) => {
        onSubmit(data, 'Brouillon');
    };

    const handleSaveAndSend = (data) => {
        setShowPopupConfirmCapaign({ show: true, data });
    };

    const handleConfirmSend = (data) => {
        setShowPopupConfirmCapaign({ show: false });
        onSubmit(data, 'À envoyer');
    };
    
    const checkIfSendCampaignIsOk = (data) => {
        if(data > 10)
            return 10

        return data
    };

    const checkIfSendMessageIsOk = (data) => {
        if(data > 2000)
            return 2000

        return data
    };

    return (
        <div className="create-campaign">
            <div className="container">
                <div className="create-campaign-container">
                    <div className="title-page">
                        <h1>Créer une campagne</h1>
                        <p>Créer une campagne en remplissant les informations suivantes</p>
                    </div>

                    <form>
                        <div className="form-group">
                            <FormInputText name="title" control={control} rules={{ required: true }} label="Titre de votre campagne *" />
                        </div>
                        <div className="form-group">
                            <FormAutocomplete
                                name="groups"
                                control={control}
                                label="Groupe"
                                options={listGroups}
                                getOptionLabel={option => option.title}
                                renderOption={(props, option) =>
                                    <li {...props} key={option.id}>
                                        {option.title} - {option.nbContact} Contacts
                                    </li>
                                }
                                clearable={true}
                                multiple={true}
                            />
                        </div>
                        <div className="form-group">
                            <FormAutocomplete
                                name="template"
                                control={control}
                                value={selectedTemplate}
                                label="Template"
                                rules={{ required: true }}
                                options={listTemplate}
                                getOptionLabel={option => option.name}
                                onChangeCustom={(newValue) => {
                                    setSelectedTemplate(newValue);
                                }}
                                renderOption={(props, option) =>
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                }
                                clearable={true}
                            />
                        </div>
                        <div className="form-group">

                            <FormAutocomplete
                                name="sendProgrammation"
                                control={control}
                                label={
                                    <span className='label-programmation'>
                                        {!subscription?.planification && (
                                            <img className="icon-premium" src={iconPremium} alt="Premium" />
                                        )}
                                        <QueryBuilderIcon />
                                        Programmation d'envoi
                                    </span>
                                }
                                disabled={!subscription?.planification}
                                options={[
                                    { id: 1, title: "Programmé" },
                                    { id: 2, title: "Immédiat" }
                                ]}
                                getOptionLabel={option => option.title}
                                renderOption={(props, option) => {
                                    let isDisabled = false;

                                    // Vérifier si la planification est désactivée et si l'option est "Programmé"
                                    if (subscription?.planification === false) {
                                        isDisabled = option.id === 1;
                                    }

                                    return (
                                        <li
                                            {...props}
                                            key={option.id}
                                            style={{
                                                pointerEvents: isDisabled ? 'none' : 'auto',
                                                opacity: isDisabled ? 0.5 : 1
                                            }}
                                            aria-disabled={isDisabled}
                                        >
                                            {subscription?.planification === false && isDisabled && <img className="icon-premium" src={iconPremium} alt="lock" />}
                                            {option.title}
                                        </li>
                                    );
                                }}

                                defaultValue={{ id: 2, title: "Immédiat" }}
                                onChangeCustom={(selectedOption) => {
                                    handleSelectProgramChange(selectedOption);
                                }}
                            />

                            {selectedSend === "Programmé" && (
                                <>
                                    <FormInputText name="dateProgrammation" control={control} rules={{ required: true }} type="date" />
                                    <FormInputText name="timeProgrammation" control={control} rules={{ required: true }} type="time" />
                                </>
                            )}
                        </div>

                        <div className="form-group">
                            <div className="list-btn">
                                <FormButtonSubmit
                                    loading={loading}
                                    label="Créer une campagne"
                                    onClick={handleSubmit(handleSaveAndSend)}
                                    type="button"
                                />
                                <FormButtonSubmit
                                    loading={loading}
                                    label="Enregistrer en tant que brouillon"
                                    onClick={handleSubmit(handleSaveAsDraft)}
                                    type="button"
                                    className="btn-secondary"
                                />
                            </div>
                        </div>
                        <p className="infoFees">Des frais Meta vont s'appliquer pour votre campagne. <br />Chaque message envoyé dans le cadre d'une campagne est soumis au tarif de 0,1186 € par message. <br /><Link to="/faq">En savoir plus</Link></p>
                    </form>
                </div>

                {(workspace?.countMessageSend <= 2000 || workspace?.countCampaignSend <= 10) ? (
                    <details className="meta-ban-info-container" open>
                        <summary className="meta-ban-info-title">
                            <p>{t('campaign.add_edit.meta_ban_info.title')}</p>
                            <ArrowDropDownIcon />
                        </summary>
                        <p className="meta-ban-info-text">
                            {t('campaign.add_edit.meta_ban_info.description')}
                        </p>
                        <div className="progress-bar-msgs-content">
                            <div className="progress-bar-msg-block">
                                <label className="progress-bar-msg-title">
                                    {t('campaign.add_edit.meta_ban_info.sent_messages')}
                                </label>
                                <div className='progress-bar-msgs-block-details'>
                                    <ProgressBar value={checkIfSendMessageIsOk(workspace?.countMessageSend)} max="2000" />
                                    <img src={trophy} alt="icon trophy" />
                                </div>
                            </div>
                            <div className="progress-bar-msg-block">
                                <label className="progress-bar-msg-title">
                                    {t('campaign.add_edit.meta_ban_info.sent_campaigns')}
                                </label>
                                <div className='progress-bar-msgs-block-details'>
                                    <ProgressBar value={checkIfSendCampaignIsOk(workspace?.countCampaignSend)} max="10" />
                                    <img src={trophy} alt="icon trophy" />
                                </div>
                            </div>
                        </div>
                    </details>
                ) : null}

            </div>

            <div className="aside-right">
                <div className="apercu">
                    <h2>Aperçu de la campagne</h2>
                    <p>Visionnez le Template que vous allez envoyer. Si vous souhaitez le modifier, allez dans la page <Link to="/template">Template</Link></p>
                </div>
                <WhatsAppExampleCampaign template={selectedTemplate} />
            </div>

            {showPopupConfirmCapaign.show && (
                <Popup closePopup={() => setShowPopupConfirmCapaign({ show: false })} customContent={true}>
                    <ConfirmCampaign
                        msg=""
                        handleConfirm={() => handleConfirmSend(showPopupConfirmCapaign.data)}
                        form={watch()}
                    />
                </Popup>
            )}

            {showPopupUpgradePlan.show &&
                <Popup closePopup={() => setShowPopupUpgradePlan({ show: false })} customContent={true}>
                    <UpgradePlan />
                </Popup>
            }
        </div>
    );
};
