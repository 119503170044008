import styles from './dashboard.module.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tutorial } from '../../components/Tutorial/Tutorial';
import { Kpi } from '../../components/Kpi/Kpi';

import { StoreContext } from '../../store/store';
import { joinWorkspace } from '../../store/index.service';

import { DashboardNotifPanel } from '../../components/NotificationsDashboard/DashboardNotifPanel';
import { MetaStatus } from '../../components/MetaStatus/MetaStatus';


export const Dashboard = () => {
    const { t } = useTranslation();
    const { workspace } = useContext(StoreContext);
    const tutoStepNumber = parseInt(workspace?.workspaceTutoStep);
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("");

    // Vérifier si le chemin contient "invit/" suivi d'un token
    const match = path.match(/\/invit\/([a-zA-Z0-9]+)/);

    if (match) {
        const token = match[1];
        joinWorkspace(token).then(res => {
            navigate('/dashboard');
        });
    }

    useEffect(() => {
        const statusSubscription = searchParams.get("statusSubscription");
        if (statusSubscription === "success") {
            setMessage("Paiement réussi ! 🎉");
        } else if (statusSubscription === "cancel") {
            setMessage("Paiement annulé ❌");
        }
    }, [searchParams]);

    return (
        <div className={styles.dashboard}>
            {tutoStepNumber !== 5 && <Tutorial />}
            <h1>Bonjour, {workspace?.workspaceTitle}</h1>
            {message && <p>{message}</p>}
            <div className={styles.contents}>
                <Kpi />
                <div className={styles.asideRight}>
                    <MetaStatus variant={workspace?.phoneQuality} />
                    {/*<DashboardNotifPanel />*/}
                </div>
            </div>
        </div>
    )
}