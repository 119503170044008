import './personalInfo.scss';

import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { getOneUser, updateUser, deleteUser } from '../../../store/index.service';

import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { Toastify } from "../../../components/UI/Toastify/Toastify";
import { ConfirmDelete } from "../../../components/UI/Popup/ConfirmDelete";
import { Popup } from "../../../components/UI/Popup/Popup";

export const PersonalInfo = () => {
    const { handleSubmit: handleSubmitInfo, control: controlInfo, setValue: setValueInfo } = useForm();
    const { handleSubmit: handleSubmitPassword, control: controlPassword } = useForm();
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState(null);
    const [showPopupDeleteAccount, setShowPopupDeleteAccount] = useState({ show: false });
    const { t } = useTranslation();

    useEffect(() => {
        getOneUser()
            .then(res => {
                setValueInfo('firstName', res.firstName);
                setValueInfo('lastName', res.lastName);
                setValueInfo('phone', res.phone);
                setValueInfo('country', res.country);
            })
    }, [setValueInfo]);

    const onSubmitInfo = async (data) => {
        setLoadingInfo(true);
        await updateUser(data);
        Toastify("updateProfil", "success");
        setLoadingInfo(false);
    }

    const onSubmitPassword = async (data) => {
        setLoadingPassword(true);
        
        if (data.password !== data['password-confirm']) {
            setErrorPassword('Les mots de passe ne correspondent pas');
            setLoadingPassword(false);
            return;
        }

        await updateUser(data);

        setLoadingPassword(false);
    }

    const handleDelete = () => {
        deleteUser();
    }

    return (
        <div className="personal-info">
            <NavigationAccount active="profile" />

            <div className="personal-info-content">
                <div className="title">
                    <h3>{t('profile.personal_data.title')}</h3>
                </div>

                <div className="form">
                    <form onSubmit={handleSubmitInfo(onSubmitInfo)}>
                        <div className="form-group">
                            <FormInputText name="firstName" control={controlInfo} rules={{ required: true }} label="Prénom" type="text" />
                            <FormInputText name="lastName" control={controlInfo} rules={{ required: true }} label="Nom" type="text" />
                        </div>
                        <div className="form-group">
                            <FormInputText name="phone" control={controlInfo} rules={{ required: false }} label="Téléphone" type="number" />
                            <FormInputText name="country" control={controlInfo} rules={{ required: false }} label="Pays" type="text" />
                        </div>

                        <div className="button">
                            <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                                <ClipLoader color={"#fff"} loading={loadingInfo} size={20} cssOverride={{ marginRight: 5 }} />
                                {t('global.actions.save')}
                            </Button>
                            {/*
                            <Button className="btn-cancel" variant="contained" disableElevation size='large' onClick={() => setShowPopupDeleteAccount({ show: true })}>
                                Supprimer le compte
                            </Button>
                            */}
                        </div>

                    </form>
                </div>

            </div>

            <div className="personal-info-content">
                <div className="title">
                    <h3>{t('profile.password.title')}</h3>
                </div>
                <div className="form">
                    <div className="error">{errorPassword}</div>
                    <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
                        <div className="form-group">
                            <FormInputText
                                name="password"
                                control={controlPassword}
                                rules={{ required: true }}
                                label={t('profile.password.form.new_password')}
                                type="password"
                            />
                        </div>
                        <div className="form-group">
                            <FormInputText
                                name="password-confirm"
                                control={controlPassword}
                                rules={{ required: true }}
                                label={t('profile.password.form.confirm_password')}
                                type="password"
                            />
                        </div>

                        <div className="button">
                            <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                                <ClipLoader color={"#fff"} loading={loadingPassword} size={20} cssOverride={{ marginRight: 5 }} />
                                {t('global.actions.save')}
                            </Button>
                        </div>

                    </form>
                </div>
            </div>

            {showPopupDeleteAccount.show && (
                <Popup closePopup={() => setShowPopupDeleteAccount({ show: false })} customContent={true}>
                    <ConfirmDelete msg={t('profile.popup_delete')} handleDelete={handleDelete} />
                </Popup>
            )}

        </div>
  	);
} 