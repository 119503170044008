import './contact.scss';
import iconUpdate from '../../../assets/images/icons/icon-edit.svg';
import iconRemove from '../../../assets/images/icons/icon-trash.svg';
import { useEffect, useState, useContext } from 'react';
import { ContactContext } from '../../../context/ContactProvider';
import { getListContact, getListContactByGroup, removeContact, removeContacts, updateContact, removeContactGroups, removeContactsGroups, updateContactBlacklist } from '../../../store/index.service';
import { DataGridPro, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Button } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { Toastify } from "../../UI/Toastify/Toastify";
import { ImportContact } from "../ImportContact/ImportContact";
import { ConfirmDelete } from "../../UI/Popup/ConfirmDelete";
import { Popup } from "../../UI/Popup/Popup";
import { AddContactToGroup } from '../Popup/AddContactToGroup';
import { UpdateContact } from '../Popup/UpdateContact';
import { CreateContact } from '../Popup/CreateContact';
import { CreateField } from '../Popup/CreateField';
import { StoreContext } from '../../../store/store';
import { ExportExcel } from './ExportExcel';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Flag from 'react-world-flags';

export const Contact = () => {
	const { workspace } = useContext(StoreContext);
	const { idGroup, nameGroup, setRefreshDataGroup, listGroup } = useContext(ContactContext);
	const [listContact, setListContact] = useState({loading: true, data: []});
	const [selectedRow, setSelectedRow] = useState([]);
	const { t } = useTranslation();

	const [showPopupCreateContact, setShowPopupCreateContact] = useState({ show: false });
	const [showPopupUpdateContact, setShowPopupUpdateContact] = useState({ show: false });
	const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
	const [showPopupAddContactToGroup, setShowPopupAddContactToGroup] = useState({ show: false });
	const [showPopupImportContact, setShowPopupImportContact] = useState({ show: false });
	const [showPopupCreateField, setShowPopupCreateField] = useState({ show: false });

	useEffect(() => {
		handleGetData();
	}, [idGroup]);

	const CustomToolbar = () => {
		return (
			<GridToolbarContainer>
				<Button size="small" onClick={() => setShowPopupCreateField({ show: true })} className="btn btn-primary">Ajouter une colonne</Button>
			</GridToolbarContainer>
		);
	}

	const handleGetData = () => {
		if (idGroup === "default" || idGroup === "") {
			getListContact().then(res => {
				setListContact({
					loading: false,
					data: res.data.contact ?? []
				});
			});
		} else {
			getListContactByGroup(idGroup).then(res => {
				setListContact({
					loading: false,
					data: res.data ?? []
				});
			});
		}
	}

	const handleDelete = () => {
		if (Array.isArray(showPopupConfirmDelete.id)) {
			if (idGroup === "default" || idGroup === "") {
				removeContacts(showPopupConfirmDelete.id).then(res => {
					Toastify("deleteContact", "success");
					handleGetData();
					setRefreshDataGroup(true);
				});
			} else {
				removeContactsGroups(showPopupConfirmDelete.id, idGroup).then(res => {
					Toastify("deleteContact", "success");
					handleGetData();
					setRefreshDataGroup(true);
				});
			}
		} else {
			if (idGroup === "default" || idGroup === "") {
				removeContact(showPopupConfirmDelete.id).then(res => {
					Toastify("deleteContact", "success");
					handleGetData();
					setRefreshDataGroup(true);
				});
			} else {
				removeContactGroups(idGroup, showPopupConfirmDelete.id).then(res => {
					Toastify("deleteContact", "success");
					handleGetData();
					setRefreshDataGroup(true);
				});
			}
		}

		setShowPopupConfirmDelete({show: false});
	}

	const handleBlacklist = (id) => {
        updateContactBlacklist(id).then(() => {
            handleGetData();
        });
    };

	const handlePopupClose = () => {
		setShowPopupImportContact({ show: false });
	};

	const handleProcessRowUpdate = async (newRow) => {
		try {
			await updateContact(newRow, newRow.id);
			Toastify("updateContact", "success");
			return newRow;
		} catch (error) {
			console.error(error);
			Toastify("updateContact", "error");
		}
	};

	const getCustomMappingColumns = (rows) => {
		const customFields = new Set(); // Utilisation d'un Set pour éviter les doublons
		
		// Vérifier que `rows` est défini et non vide
		if (rows && rows.length > 0) {
			// Parcourir les lignes pour récupérer tous les champs de customMapping
			rows.forEach(row => {
				if (row.customFields) {
					Object.keys(row.customFields).forEach(field => {
						customFields.add(field); // Ajouter chaque champ à la liste
					});
				}
			});
		}
	
		// Créer une colonne pour chaque champ dynamique
		return Array.from(customFields).map((field) => ({
			field,
			headerName: field,  // Le nom du champ comme header
			flex: 0.6,
			editable: false,
			renderCell: (params) => params.row.customFields[field] || '', // Afficher la valeur du champ ou vide s'il n'y en a pas
		}));
	};
	

	const columns = [
		{ field: "firstName", headerName: t('contact.table.firstName'),  flex: 0.5, editable: true },
		{ field: "lastName", headerName: t('contact.table.lastName'), flex: 0.5, editable: true },
		{ field: "email", headerName: t('contact.table.email'), flex: 1, editable: true },
		{
				field: "phone",
				headerName: t('contact.table.phone'),
				flex: 1,
				editable: true,
				renderCell: (params) => {
						const { phone, phoneValid, phoneCountry } = params.row;

						return (
								<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
				{/* Icône de validité */}
				{phoneValid ? 
												<CheckIcon style={{ color: "green" }} /> : 
												<CloseIcon style={{ color: "red" }} />
										}

										{/* Affichage du pays */}
										{phoneCountry && (
												<div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
														<Flag code={phoneCountry} style={{ width: 20, height: 15 }} />
												</div>
										)}
										{/* Numéro de téléphone */}
										<span>{phone}</span>
								</div>
						);
				}
		},
		{
			field: "groupes",
			headerName: t('contact.table.groups'),
			flex: 2,
			editable: false,
			type: "singleSelect",
			renderCell: (params) => (
				<div className="list-group-contact">
					{params.row.groups?.map((item) => (
						<div className="groups-item" key={item.id}>{item.name}</div>
					))}
				</div>
      )},
		...getCustomMappingColumns(listContact.data),
		{
			field: 'blacklisted',
			headerName: t('contact.table.blacklisted'),
			flex: 0.5,
			sortable: false,
			type: "boolean",
			renderCell: (params) => (
				<label className="switch">
						<input type="checkbox" onChange={() => handleBlacklist(params.row.id)} checked={params.row.blacklisted} />
						<span className="slider round"></span>
				</label>
			)
		},
		{
			field: 'actions',
			type: "actions",
			flex: 0.6,
			getActions: (params) => {
				const actions = [
					workspace?.workspaceRole === 'admin' ? (
						<GridActionsCellItem
							icon={<img src={iconUpdate} alt="icon update" />}
							onClick={() => setShowPopupUpdateContact({ show: true, id: params.row.id })}
							label="Modifier"
						/>
					) : null,
					workspace?.workspaceRole === 'admin' ? (
						<GridActionsCellItem
							icon={<img src={iconRemove} alt="icon remove" />}
							onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
							label="Supprimer"
						/>
					) : null
				];
				return actions.filter((action) => action !== null);
			}
		}
	];

	return (
		<div className="contact">
			<div className="background-white">
				<h1>{nameGroup} </h1>
				{workspace?.workspaceRole === 'admin' &&
					<div className="list-action-group">
						{selectedRow.length > 0 && 
							<div className="list-action">
								<div className="action add" onClick={() => setShowPopupAddContactToGroup({ show: true, id: selectedRow })}>
									<FontAwesomeIcon icon={faPlus} />
									<span>Ajouter à un groupe</span>
								</div>
								<div className="action delete" onClick={() => setShowPopupConfirmDelete({ show: true, id: selectedRow })}>
									<FontAwesomeIcon icon={faTrash} />
									<span>Retirer du groupe</span>
								</div>
							</div>
						}

						<div className="list-action">
							<div className="action add" onClick={() => setShowPopupCreateContact({ show: true })}>
								<FontAwesomeIcon icon={faPlus} />
								<span>Ajouter un contact</span>
							</div>
							<div className="action add" onClick={() => setShowPopupImportContact({ show: true })}>
								<FontAwesomeIcon icon={faFileImport} />
								<span>Importer des contacts</span>
							</div>
							<ExportExcel data={listContact.data} fileName="contacts" groups={nameGroup} selectedRow={selectedRow} />
						</div>
					</div>
				}
			
				<DataGridPro 
					rows={listContact.data} 
					columns={columns} 
					pageSize={20} 
					className="table" 
					loading={listContact.loading}
					checkboxSelection
					editable={true}
					slots={{
						toolbar: (params) => <CustomToolbar />
					}}
					pagination
					pageSizeOptions={[20, 50, 100]}
					processRowUpdate={handleProcessRowUpdate}
					onProcessRowUpdateError={error => console.error(error)}
					onRowSelectionModelChange={(selection) => setSelectedRow(selection)}
					disableRowSelectionOnClick 
					disableColumnSelector
					disableVirtualization
					disableDensitySelector
				/>
			</div>

			{ showPopupConfirmDelete.show &&
				<Popup closePopup={() => setShowPopupConfirmDelete({ show: false })} customContent={true}>
					<ConfirmDelete msg={t('contact.popup_delete')} handleDelete={handleDelete} />
				</Popup>
			}

			{showPopupAddContactToGroup.show && (
				<Popup closePopup={() => setShowPopupAddContactToGroup({ show: false })} customContent={true}>
					<AddContactToGroup setRefreshDataGroup={setRefreshDataGroup} listContact={showPopupAddContactToGroup.id} listGroup={listGroup} />
				</Popup>
			)}

			{showPopupUpdateContact.show && (
				<Popup closePopup={() => setShowPopupUpdateContact({ show: false })} customContent={true}>
					<UpdateContact setRefreshDataGroup={setRefreshDataGroup} idContact={showPopupUpdateContact.id} listGroup={listGroup} handleGetData={handleGetData} />
				</Popup>
			)}

			{showPopupCreateContact.show && (
				<Popup closePopup={() => setShowPopupCreateContact({ show: false })} customContent={true}>
					<CreateContact setRefreshDataGroup={setRefreshDataGroup} listGroup={listGroup} handleGetData={handleGetData} />
				</Popup>
			)}

			{showPopupImportContact.show && (
				<ImportContact open={showPopupImportContact.show} handleClose={handlePopupClose} setRefreshDataGroup={setRefreshDataGroup} handleGetData={handleGetData} />
			)}

			{showPopupCreateField.show && (
				<Popup closePopup={() => setShowPopupCreateField({ show: false })} customContent={true}>
					<CreateField setRefreshDataGroup={setRefreshDataGroup} listGroup={listGroup} handleGetData={handleGetData} />
				</Popup>
			)}

		</div>
	);
};

