import './contact.scss';
import { ContactProvider } from "../../context/ContactProvider";
import { Contact } from "../../components/Contact/Contact/Contact";
import { Groups } from "../../components/Contact/Groups/Groups";

export const Contacts = () => {
    return (
        <div className="contact-groups">
            <ContactProvider>
                <Groups />
                <Contact />
            </ContactProvider>
        </div>
    )
}