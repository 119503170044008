import React, { useRef, useMemo, useCallback } from 'react';
import { Controller } from "react-hook-form";

// Assets
import defaultCarrousel from "../../../assets/images/default-carrousel.png";

// Utils
import { Toastify } from '../../UI/Toastify/Toastify';

// Styles
import './uploadFile.scss';

// Constants
import { IMG_SIZE_LIMIT } from '../../../utils/constants';

const FileInput = ({ onChange, value }) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = useCallback((event) => {
        const fileUploaded = event.target.files[0]; // Sélection du fichier
        if (fileUploaded) {
            if (fileUploaded.size > IMG_SIZE_LIMIT) {
                Toastify('imgSizeLimit', 'error');
                event.target.value = '';
                return;
            }
            onChange(fileUploaded); // Transmettre le fichier réel (File object)
        }
    }, [onChange]);

    const getImage = useCallback((val) => {
        if (!val) return defaultCarrousel;
        // base64 string
        if (typeof val === 'string' && val.startsWith('data:image')) return val;
        if (typeof value === 'string' ) return val;
        return URL.createObjectURL(val);
    }, []);

    return (
        <div className="upload-file">
            <button className="button-upload" onClick={handleClick} type="button">
                <div className="image-container">
                    {value ? (
                        <img
                            src={getImage(value)}
                            alt="Aperçu du fichier"
                            className="image-preview"
                        />
                    ) : (
                        <img
                            src={defaultCarrousel}
                            alt="Upload"
                            className="default-image"
                        />
                    )}
                </div>
            </button>
            <input
                type="file"
                accept="image/*"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export const UploadFile = ({
    control,
    name,
    rules,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <FileInput
                    onChange={onChange}
                    value={value}
                />
            )}
        />
    );
};
