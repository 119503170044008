import './pricingSubscription.scss';
import { React, useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { getListProduct, createCheckoutSession } from '../../store/index.service';
import { loadStripe } from '@stripe/stripe-js';
import { StoreContext } from '../../store/store';

export const PricingSubscription = () => {
    const [listProduct, setListProduct] = useState({loading: true, data: []});
    const { workspace = {} } = useContext(StoreContext);
    const idProductStripe = workspace && workspace.subscription ? workspace.subscription.idProductStripe : null;
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

    console.log(process.env.REACT_APP_STRIPE_API_KEY);

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListProduct().then(res => {
            setListProduct({
                loading: false,
                data: res ?? []
            });
        });
    }

    const handlePayment = async (idStripePrice) => {
        try {
            // Préparer les données à envoyer à votre endpoint backend
            const data = { idStripePrice };
    
            // Appeler votre endpoint backend pour créer une session de paiement
            const response = await createCheckoutSession(data);

            if (!response.data.sessionId) {
                throw new Error("Session ID non reçu du backend");
            }
    
            // Initialiser Stripe
            const stripe = await stripePromise;
    
            // Rediriger vers la page de paiement Stripe
            const { error } = await stripe.redirectToCheckout({
                sessionId: response.data.sessionId,
            });

            console.log("Redirection vers Stripe Checkout:", error.message);
    
            // Gérer les erreurs de redirection
            if (error) {
                console.error("Erreur lors de la redirection vers Stripe Checkout:", error);
            }
        } catch (error) {
            // Gérer les erreurs de requête et autres erreurs
            console.error("Erreur lors de la création de la session de paiement:", error);
        }
    };

    return (
        <div className="pricingSubscription">
            <div className="priceList">
                <div className="priceListHeader">
                    <h2>Choisissez votre abonnement</h2>
                </div>
                <div className="priceListContent">
                    {listProduct.data.map((product, index) => (
                        <div className="priceListItem background-white" key={index}>
                            <h3>{product.title}</h3>
                            <p className="content">{product.content}</p>
                            <p className="price">{product.price.price}€ / mois</p>
                            <ul>
                                {product.item.map((item, index) => (
                                    <li key={index}> <FontAwesomeIcon icon={faCheck} size="lg" /> {item.label} {item.value}</li>
                                ))}
                            </ul>

                            {(workspace?.workspaceRole === 'admin' && product.idProductStripe !== idProductStripe) && (  
                                <button className="btn" onClick={() => handlePayment(product.price.idStripePrice)}>Choisir</button>
                            )}

                        </div>
                    ))}
                </div>
            </div>
        </div>
  	);
} 