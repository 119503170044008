import './navigationAccount.scss';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { StoreContext } from '../../store/store';

export const NavigationAccount = (props) => {
    const { workspace } = useContext(StoreContext);
    
    return (
        <div className="navigation-account">
            <Link to="/compte/profile" className={props.active === 'profile' ? 'active' : ''}>Information personnelle</Link>
            <Link to="/compte/meta" className={props.active === 'meta' ? 'active' : ''}>Meta</Link>
            {/*<Link to="/compte/integration" className={props.active === 'integration' ? 'active' : ''}>Intégration</Link>*/}
            {workspace?.workspaceRole === 'admin' && 
                <>
                    <Link to="/compte/espace-de-travail" className={props.active === 'workspace' ? 'active' : ''}>Mon espace</Link>
                </>
            }
            <Link to="/compte/abonnement" className={props.active === 'subscription' ? 'active' : ''}>Mon abonnement</Link>
            <Link to="/compte/facture" className={props.active === 'invoice' ? 'active' : ''}>Mes factures</Link>
            <Link to="/politique-de-confidentialite" className={props.active === 'cgu' ? 'active' : ''}>CGU</Link>
        </div>
  	);
} 