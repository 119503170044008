import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DataGridPro, GridActionsCellItem, useGridApiContext } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';
import Pagination from "@mui/material/Pagination";
import { Menu, MenuItem, IconButton, Link } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { StoreContext } from "../../store/store";

// Components
import { Popup } from "../../components/UI/Popup/Popup";
import { ConfirmDelete } from "../../components/UI/Popup/ConfirmDelete";
import { ConfirmPopup } from "../../components/UI/Popup/ConfirmPopup";
import { UpgradePlan } from "../../components/UI/Popup/UpgradePlan";
import { Button } from "../../components/UI/Button/Button";
import { Tag } from "../../components/UI/Tag/Tag";

// Services
import { deleteCampaign, getListCampaign, duplicateCampaign, exportCampaign, stopCampaign, pauseCampaign, playCampaign } from '../../store/index.service';

// Assets
import iconUpdate from '../../assets/images/icons/icon-edit.svg';
import iconRemove from '../../assets/images/icons/icon-trash.svg';
import iconDuplicate from '../../assets/images/icons/icon-duplicate.svg';
import iconPremium from "../../assets/images/icons/icon-premium.svg";
import iconPlay from "../../assets/images/icons/icon-play.svg";
import iconPause from "../../assets/images/icons/icon-stop.svg";
import iconStop from "../../assets/images/icons/icon-pause.svg";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

// Constants
import { CAMPAIGN_STATUS } from "../../utils/constants";

export const Campaign = () => {
    const { workspace, subscription } = useContext(StoreContext);
    const [listCampaign, setListCampaign] = useState({ loading: true, data: [] });
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({ show: false });
    const [showPopupConfirmDuplicate, setShowPopupConfirmDuplicate] = useState({ show: false });
    const [showPopupUpgradePlan, setShowPopupUpgradePlan] = useState({ show: false });
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [totalRows, setTotalRows] = useState(0);
    const [menuAnchor, setMenuAnchor] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [currentNbRows, setCurrentNbRows] = useState(10);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleOpenMenu = (event, rowId) => {
        setMenuAnchor((prev) => ({ ...prev, [rowId]: event.currentTarget }));
    };

    const handleCloseMenu = (rowId) => {
        setMenuAnchor((prev) => ({ ...prev, [rowId]: null }));
    };

    const statusVariants = {
        [CAMPAIGN_STATUS.DRAFT]: { label: "Brouillon", variant: "grey" },
        [CAMPAIGN_STATUS.PROGRAMMED]: { label: "Programmée", variant: "grey" },
        [CAMPAIGN_STATUS.INPROGRESS]: { label: "En cours", variant: "blue" },
        [CAMPAIGN_STATUS.PAUSE]: { label: "En pause", variant: "orange" },
        [CAMPAIGN_STATUS.STOPPED]: { label: "Annulée", variant: "red" },
        [CAMPAIGN_STATUS.FINISHED]: { label: "Terminée", variant: "green" }
    };

    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 12 : 6,
            bottom: params.isLastVisible ? 12 : 6,
        };
    }, []);

    const subscriptionState = useMemo(() => !subscription?.tracking, [subscription]);
    const premiumRandomValues = useMemo(() => Math.floor(Math.random() * 201), []);

    const columns = [
        {
            field: 'title',
            headerName: t('campaign.table.title'),
            flex: 1,
        },
        {
            field: 'template',
            valueGetter: (params) => params?.name,
            headerName: t('campaign.table.template'),
            flex: 1,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                    <Tooltip title={t('campaign.table.template_tooltip')}>
                        <InfoOutlinedIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            )
        },
        {
            field: 'groups',
            valueGetter: (params) => params.title,
            headerName: t('campaign.table.group'),
            flex: 1,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                    <Tooltip title={t('campaign.table.group_tooltip')}>
                        <InfoOutlinedIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            )
        },
        {
            field: 'createdAt',
            headerName: t('campaign.table.createdAt'),
            type: 'dateTime',
            flex: 0.9,
            valueGetter: (params) => params ? new Date(params.date) : null
        },
        {
            field: 'nbSend',
            headerName: t('campaign.table.msgSent'),
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
            align: 'center'
        },
        {
            field: 'nbDelivered',
            headerName: t('campaign.table.msgDelivered'),
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
            align: 'center'
        },
        {
            field: 'nbFail',
            headerName: t('campaign.table.msgFailed'),
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
            align: 'center'
        },
        {
            field: 'nbView',
            headerName: t('campaign.table.msgViewed'),
            flex: 0.3,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
        },
        {
            field: 'nbClick',
            headerName: t('campaign.table.msgClicked'),
            flex: 0.4,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            renderHeader: (params) => (
                subscriptionState ?
                    <span className="header-premium">
                        {params.colDef.headerName}
                        <img className="icon-premium" src={iconPremium} alt="icon premium" />
                    </span>
                    :
                    <span>{params.colDef.headerName}</span>
            ),
            renderCell: (params) => (
                subscriptionState ?
                    <span className="not-premium">{premiumRandomValues}</span>
                    :
                    <span>{params.row.nbClick ? params.row.nbClick : 0}</span>
            )
        },
        // { field: 'nbSpam', headerName: 'Spam', flex: 0.4 },
        // { field: 'nbNotView', headerName: 'Non lus', flex: 0.4 },
        {
            field: 'status',
            headerName: t('campaign.table.status'),
            flex: 0.7,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const formatDate = (dateObj) => {
                    if (!dateObj || !dateObj.date) return '';
                    const date = new Date(dateObj.date);
                    return date.toLocaleDateString('fr-FR'); // Format de date en français
                };

                const formatTime = (timeObj) => {
                    if (!timeObj || !timeObj.date) return '';
                    const time = new Date(timeObj.date);
                    return time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }); // Format heure:minute
                };

                const status = statusVariants[params.row.status];
                const label = params.row.status === CAMPAIGN_STATUS.PROGRAMMED ? ( // Si le statut est "Programmée"
                    <>
                        {status.label + " "}
                        {formatDate(params.row.dateProgrammation)} à&nbsp;
                        {formatTime(params.row.timeProgrammation)}
                    </>
                ) : (
                    status.label
                );

                const actions = [
                    workspace?.workspaceRole === 'admin' && params.row.status === CAMPAIGN_STATUS.INPROGRESS ? (
                        <GridActionsCellItem
                            icon={<img src={iconPause} alt="icon pause" />}
                            onClick={() => handlePauseCampaign(params.row.id)}
                            label="Pause"
                        />
                    ) : null,
                    workspace?.workspaceRole === 'admin' && params.row.status === CAMPAIGN_STATUS.PAUSE ? (
                        <GridActionsCellItem
                            icon={<img src={iconPlay} alt="icon play" />}
                            onClick={() => handlePlayCampaign(params.row.id)}
                            label="Play"
                        />
                    ) : null,
                    workspace?.workspaceRole === 'admin' && (params.row.status === CAMPAIGN_STATUS.INPROGRESS || params.row.status === CAMPAIGN_STATUS.PAUSE) ? (
                        <GridActionsCellItem
                            icon={<img src={iconStop} alt="icon stop" />}
                            onClick={() => handleStopCampaign(params.row.id)}
                            label="Stop"
                        />
                    ) : null
                ];

                return <>
                    <Tag
                        variant={status.variant}
                        label={label}
                    />
                    {actions.filter((action) => action !== null)}
                </>
            }
        },
        {
            field: 'actions',
            type: "actions",
            minWidth: 150,
            flex: 0.7,
            align: 'right',
            renderCell: (params) => {
                const open = Boolean(menuAnchor[params.row.id]);
                return (
                    <>
                        {workspace?.workspaceRole === 'admin' ? (
                            <Link onClick={() => navigate(`/campagnes/${params.row.id}`)}>
                                <GridActionsCellItem
                                    icon={<VisibilityOutlinedIcon />}
                                    label="Export"
                                />
                            </Link>
                        ) : null}

                        <IconButton onClick={(event) => handleOpenMenu(event, params.row.id)}>
                            <MoreHorizRoundedIcon />
                        </IconButton>

                        <Menu
                            anchorEl={menuAnchor[params.row.id]}
                            open={open}
                            onClose={() => handleCloseMenu(params.row.id)}
                        >
                            {workspace?.workspaceRole === 'admin' ? (
                                <MenuItem onClick={() => {
                                    setShowPopupConfirmDuplicate({ show: true, id: params.row.id });
                                    handleCloseMenu(params.row.id);
                                }}>
                                    <img src={iconDuplicate} alt="icon duplicate" style={{ marginRight: 8 }} /> Dupliquer
                                </MenuItem>
                            ) : null}
                            {workspace?.workspaceRole === 'admin' && params.row.status !== "À envoyer" ? (
                                <MenuItem>
                                    <Link onClick={() => {
                                        navigate(`/campagnes/update/${params.row.id}`);
                                        handleCloseMenu(params.row.id);
                                    }}>
                                        <img src={iconUpdate} alt="icon update" style={{ marginRight: 8 }} /> Modifier
                                    </Link>
                                </MenuItem>
                            ) : null}
                            {workspace?.workspaceRole === 'admin' ? (
                                <MenuItem onClick={() => {
                                    setShowPopupConfirmDelete({ show: true, id: params.row.id });
                                    handleCloseMenu(params.row.id);
                                }}>
                                    <img src={iconRemove} alt="icon remove" style={{ marginRight: 8 }} /> Supprimer
                                </MenuItem>
                            ) : null}
                            {workspace?.workspaceRole === 'admin' ? (
                                <MenuItem onClick={() => {
                                    handleExportCampaign(params.row.id);
                                    handleCloseMenu(params.row.id);
                                }}>
                                    <FontAwesomeIcon icon={faFileExport} style={{ marginRight: 8, marginLeft: 6 }} /> Export
                                </MenuItem>
                            ) : null}
                        </Menu>
                    </>
                );
            }
        }
    ];

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = (currentPage = paginationModel.page, currentPageSize = paginationModel.pageSize) => {
        setListCampaign((prev) => ({ ...prev, loading: true }));

        getListCampaign(currentPage + 1, currentPageSize)
            .then((res) => {
                setListCampaign({
                    loading: false,
                    data: res.listCampaign ?? [],
                });
                setTotalRows(res.total);
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération des données :", error);
                setListCampaign({ loading: false, data: [] });
            });
    };

    const handleDelete = () => {
        deleteCampaign(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const handleDuplicate = () => {
        duplicateCampaign(showPopupConfirmDuplicate.id).then(() => {
            handleGetData();
        });
    }

    const handleExportCampaign = (id) => {
        exportCampaign(id).then((res) => {
            if (res.data) {
                const a = document.createElement("a");
                a.href = `${process.env.REACT_APP_API_URL}/${res.data.file}`;
                a.click();
            } else {
                console.error("Erreur : fichier non trouvé.");
            }
        }).catch(error => {
            console.error("Erreur lors de l'exportation :", error);
        });
    };

    const handleStopCampaign = (id) => {
        console.log("Stop campaign", id);
        stopCampaign(id).then(() => {
            handleGetData();

        });
    };

    const handlePauseCampaign = (id) => {
        console.log("Pause campaign", id);
        pauseCampaign(id).then(() => {
            handleGetData();
        });
    };

    const handlePlayCampaign = (id) => {
        console.log("Play campaign", id);
        playCampaign(id).then(() => {
            handleGetData();
        });
    };

    const CustomPagination = (props) => {
        const { loading } = props;
        const apiRef = useGridApiContext();

        let pageSize = apiRef.current?.state.pagination.pageSize || 10;

        const handlePageChange = (_, value) => {
            const pageSize = apiRef.current?.state.pagination.pageSize || 10;
            const maxRows = apiRef.current?.getRowsCount() || 0;
            const newNbRows = Math.min(value * pageSize, maxRows);

            setCurrentNbRows(newNbRows);
            setCurrentPage(value);
            apiRef.current.setPage(value - 1);
        };

        const rowCount = apiRef.current?.getRowsCount() || 0;
        const pageCount = Math.ceil(rowCount / pageSize);

        return (
            <div className={`custom-pagination ${loading ? 'hidden' : ''}`}>
                <p className="custom-pagination-statetext">
                    <span className="custom-pagination-statetext-bold">{currentNbRows}</span> éléments sur <span className="custom-pagination-statetext-bold">{rowCount}</span>
                </p>
                <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={handlePageChange}
                />
            </div>
        );
    };

    return (
        <div className="campaign">
            <div className="background-white-title">
                <div className="title">
                    <h1>{t('campaign.title')}</h1>
                    {workspace?.metaTier === null && <p>Vous devez vérifier votre entreprise sur Meta pour envoyer plus de 250 messages</p>}
                </div>
                {workspace?.workspaceRole === 'admin' &&
                    <div className="actions">
                        {subscription === null || subscription?.nbMaxMessageSend <= workspace.countMessageSendInMonth ? (
                            <Button variant="full" color="degradPurple" onClick={() => setShowPopupUpgradePlan({ show: true })} content={t('campaign.add_btn')} />
                        ) : (
                            <Button variant="full" color="degradPurple" to="/campagnes/create" content={t('campaign.add_btn')} />
                        )}
                    </div>
                }
            </div>

            <DataGridPro
                className="table"
                rows={listCampaign.data}
                columns={columns}
                rowCount={totalRows}
                rowHeight={65}
                autoHeight
                loading={listCampaign.loading}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                pageSizeOptions={[10]}
                paginationModel={paginationModel}
                getRowSpacing={getRowSpacing}
                onPaginationModelChange={(model) => {
                    setPaginationModel(model);
                    handleGetData(model.page, model.pageSize);
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            'nbSpam': false,
                            'nbNotView': false
                        },
                    },
                }}
                slots={{
                    columnSortedAscendingIcon: SwapVertIcon,
                    columnSortedDescendingIcon: SwapVertIcon,
                    columnUnsortedIcon: SwapVertIcon,
                    columnMenuIcon: MoreHorizRoundedIcon,
                    pagination: (props) => <CustomPagination {...props} loading={listCampaign.loading} />
                }}
                disableColumnReorder={true}
            />

            {showPopupConfirmDelete.show &&
                <Popup closePopup={() => setShowPopupConfirmDelete({ show: false })} customContent={true}>
                    <ConfirmDelete msg={t('campaign.popup_delete')} handleDelete={handleDelete} />
                </Popup>
            }

            {showPopupConfirmDuplicate.show &&
                <Popup closePopup={() => setShowPopupConfirmDuplicate({ show: false })} customContent={true}>
                    <ConfirmPopup msg={t('campaign.popup_duplicate')} handleDelete={handleDuplicate} />
                </Popup>
            }

            {showPopupUpgradePlan.show &&
                <Popup closePopup={() => setShowPopupUpgradePlan({ show: false })} customContent={true}>
                    <UpgradePlan />
                </Popup>
            }

        </div>
    )
}