import styles from './Tag.module.scss';

// Possible variants :
// - grey
// - blue
// - orange
// - red
// - green

export const Tag = ({ variant = "grey", label = "Non défini" }) => {
    return (
        <span className={`${styles.statusCampaign} ${styles[variant]}`}>
            {label}
        </span>
    );
};
