import './workspace.scss';
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { getOneWorkspaceByUser, updateWorkspace } from '../../../store/index.service';
import { ListMemberInWorkspace } from '../../../components/ListMemberInWorkspace/ListMemberInWorkspace';
import { Toastify } from "../../../components/UI/Toastify/Toastify";

export const Workspace = () => {
    const { handleSubmit, control, setValue } = useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getOneWorkspaceByUser()
            .then(res => {
                setValue('title', res.workspaceTitle);
                setValue('companyName', res.workspaceCompanyName);
                setValue('content', res.workspaceContent);
                setValue('vatNumber', res.workspaceVatNumber);
                setValue('address', res.workspaceAddress.address);
                setValue('postalCode', res.workspaceAddress.postalCode);
                setValue('city', res.workspaceAddress.city);
                setValue('country', res.workspaceAddress.country);
            })
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);
        await updateWorkspace(data);
        Toastify("updateWorkspace", "success");
        setLoading(false);
    }

    return (
        <div className="workspace">
            <NavigationAccount active="workspace" />

            <div className="workspace-content">
                <div className="title">
                    <h3>Espace de travail : </h3>
                </div>

                <div className="form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <FormInputText name="title" control={control} rules={{ required: true }} label="Nom de votre espace de travail" />
                            <FormInputText name="companyName" control={control} rules={{ required: true }} label="Entreprise" />
                            <FormInputText name="vatNumber" control={control} rules={{ required: false }} label="Numéro de TVA" />
                        </div>

                        <div className="form-group">
                            <FormInputText name="address" control={control} rules={{ required: false }} label="Addresse" />
                            <FormInputText name="postalCode" control={control} rules={{ required: false }} label="Code postal" />
                            <FormInputText name="city" control={control} rules={{ required: false }} label="Ville" />
                            <FormInputText name="country" control={control} rules={{ required: false }} label="Pays" />
                        </div>

                        <div className="button">
                            <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                                <ClipLoader color={"#fff"} loading={loading} size={20} cssOverride={{ marginRight: 5 }} />
                                Enregistrer
                            </Button>
                        </div>
                    </form>
                </div>
                
            </div>

            <div className="workspace-content">
                <ListMemberInWorkspace />
            </div>

        </div>
  	);
} 