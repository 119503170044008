export const BUTTONS_TYPE = {
  web: 'web',
  phone: 'phone',
  stopMarket: 'stopMarket',
};

export const IMG_SIZE_LIMIT = 5 * 1024 * 1024; // 5 Mo

export const VIDEO_SIZE_LIMIT = 12 * 1024 * 1024; // 12 Mo

export const CAMPAIGN_STATUS = {
  DRAFT: 0,
  PROGRAMMED: 1,
  INPROGRESS: 2,
  PAUSE: 3,
  STOPPED: 4,
  FINISHED: 5
}