import { useEffect } from "react";
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Components
import { WhakupRouter } from "./WhakupRouter";
import { ScrollToTop } from "./components/UI/ScrollToTop/ScrollToTop";

// Utils
import { useAppContext } from "./store/store";

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_MUI);

const theme = createTheme({
	mixins: {
		MuiDataGrid: {
			containerBackground: 'rgba(115, 30, 112, 0.10)',
		},
	},
});

export const App = () => {
	const { lang } = useAppContext();
	const { i18n } = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<div className="whakup">
					<ScrollToTop />
					<WhakupRouter />
				</div>
			</ThemeProvider>
		</BrowserRouter>
	);
}