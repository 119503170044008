import { LinearProgress, Typography, Box } from "@mui/material";
import { useMemo } from "react";
import "./ProgressBar.scss";

export const ProgressBar = ({ value, max }) => {
    const percentage = useMemo(() => (value / max) * 100, [value, max]);
    const leftPosition = useMemo(() => `${(value / max) * 85}%`, [value, max]); // 85 est une valeur provisoire et devra être changée quand le lien avec le back arrivera
    return (
        <Box className="progress-container">
            <Box className="progress-wrapper">
                <LinearProgress
                    variant="determinate"
                    value={percentage}
                    className="progress-bar"
                />
                <Box className="block-progress-max">
                    <Typography variant="body2" className="progress-max">{max}</Typography>
                </Box>

            </Box>

            <Typography variant="body2" className="progress-min">0</Typography>

            <Typography
                variant="body2"
                className="progress-value"
                style={{ left: leftPosition }}
            >
                {value}
            </Typography>
        </Box>
    );
};
