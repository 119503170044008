import './oneCampaign.scss';
import iconArrowLeft from '../../../assets/images/icons/icon-arrow-left.svg';
import iconDownload from '../../../assets/images/icons/icon-download.svg';
import { useState, useEffect, useContext } from "react";
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { getOneCampaign, generateGroupsCampaign, exportGrouOneCampaign, blacklistedGroupCampaign } from '../../../store/index.service';
import { WhatsAppExampleCampaign } from "../../../components/WhatsAppExample/WhatsAppExampleCampaign";
import { StatsItem } from "../../../components/OneCampaign/StatsItem/StatsItem";
import { StatsGraph } from "../../../components/OneCampaign/StatsGraph/StatsGraph";
import { StatsMessage } from "../../../components/OneCampaign/StatsMessage/StatsMessage";
import { StatsClick } from "../../../components/OneCampaign/StatsClick/StatsClick";
import { Toastify } from "../../../components/UI/Toastify/Toastify";

import { useParams, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { StoreContext } from "../../../store/store";
import { set } from 'react-hook-form';
import ClipLoader from "react-spinners/ClipLoader";

export const OneCampaign = () => {
    let { id } = useParams();
    const { workspace, subscription } = useContext(StoreContext);
    const { t } = useTranslation();
    const [filterContact, setFilterContact] = useState("envois");
    const [oneCampaign, setOneCampaign] = useState(null);
    const [template, setTemplate] = useState(null);
    const [oneCampaignTable, setOneCampaignTable] = useState({loading: true, data: []});
    const [isDownloading, setIsDownloading] = useState(false);
    const [isGeneratingGroup, setIsGeneratingGroup] = useState(false);
    const [valueClick, setValueClick] = useState(0);

    useEffect(() => {
        getOneCampaign(id).then((data) => {
            setOneCampaign(data);
            setTemplate(data.template);
            setOneCampaignTable({loading: false, data: data.contactSend});
        });
    } , []);

    useEffect(() => {
        if(oneCampaign) {
            switch (filterContact) {
                case "send":
                    setOneCampaignTable({loading: false, data: oneCampaign.contactSend});
                    break;
                case "delivered":
                    setOneCampaignTable({loading: false, data: oneCampaign.contactDelivered});
                    break;
                case "notView":
                    setOneCampaignTable({loading: false, data: oneCampaign.contactNotView});
                    break;
                case "view":
                    setOneCampaignTable({loading: false, data: oneCampaign.contactView});
                    break;
                case "failed":
                    setOneCampaignTable({loading: false, data: oneCampaign.contactFail});
                    break;
                case "clics":
                    setOneCampaignTable({loading: false, data: oneCampaign.contactClick});
                    break;
                case "spam":
                    setOneCampaignTable({loading: false, data: oneCampaign.contactSpam});
                    break;
                default:
                    setOneCampaignTable({loading: false, data: oneCampaign.contactSend});
                    break;
            }
        }
    }, [filterContact]);

    const generateStatsData = (data) => {
        if(!data) return [];
        const totalContactsGroup = data.nbContactGroup || 1;
        const totalContacts = data.nbSend || 1;

        return [
            {
                pourcent: ((data.nbSend / totalContactsGroup) * 100).toFixed(1),
                nbMessage: data.nbSend,
                title: "Envoyés",
                contentTooltip: "campaign.statsItem.openRate",
                color: getColor('send', (data.nbSend / totalContactsGroup) * 100)
            },
            {
                pourcent: ((data.nbView / totalContacts) * 100).toFixed(1),
                nbMessage: data.nbView,
                title: "Lus",
                contentTooltip: "campaign.statsItem.clickRate",
                color: getColor('lus', (data.nbView / totalContacts) * 100),
            },
            {
                pourcent: ((data.nbNotView / totalContacts) * 100).toFixed(1),
                nbMessage: data.nbNotView,
                title: "Non lus",
                contentTooltip: "campaign.statsItem.conversionRate",
                color: getColor('nonLus', (data.nbNotView / totalContacts) * 100),
            },
            {
                pourcent: ((data.nbFail / totalContacts) * 100).toFixed(1),
                nbMessage: data.nbFail,
                title: "Échoués",
                contentTooltip: "campaign.statsItem.unsubRate",
                color: getColor('echoue', (data.nbFail / totalContacts) * 100),
            },
            {
                pourcent: ((data.nbSpam / totalContacts) * 100).toFixed(1),
                nbMessage: data.nbSpam,
                title: "Signalés comme spam",
                contentTooltip: "campaign.statsItem.spamRate",
                color: getColor('spam', (data.nbSpam / totalContacts) * 100),
            },
            {
                pourcent: ((data.nbClick / totalContacts) * 100).toFixed(1),
                nbMessage: data.nbClick,
                title: "Clics",
                contentTooltip: "campaign.statsItem.replyRate",
                color: getColor('clics', (data.nbClick / totalContacts) * 100),
            },
        ];
    };

    const getColor = (type, percentage) => {
        switch (type) {
            case 'lus':
                if (percentage < 50) return 'red';
                if (percentage >= 50 && percentage <= 64) return 'orange';
                if (percentage >= 65 && percentage <= 74) return 'lightgreen';
                if (percentage >= 75) return 'green';
                break;
            case 'nonLus':
                if (percentage > 50) return 'red';
                if (percentage >= 36 && percentage <= 50) return 'orange';
                if (percentage >= 26 && percentage <= 35) return 'lightgreen';
                if (percentage <= 25) return 'green';
                break;
            case 'echoue':
                if (percentage >= 17) return 'red';
                if (percentage >= 10 && percentage <= 16) return 'orange';
                if (percentage >= 5 && percentage <= 9) return 'lightgreen';
                if (percentage <= 4) return 'green';
                break;
            case 'spam':
                if (percentage >= 5) return 'red';
                if (percentage >= 3 && percentage < 5) return 'orange';
                if (percentage >= 1 && percentage < 3) return 'lightgreen';
                if (percentage < 1) return 'green';
                break;
            case 'clics':
                if (percentage >= 9) return 'red';
                if (percentage >= 10 && percentage < 29) return 'orange';
                if (percentage >= 30 && percentage < 39) return 'lightgreen';
                if (percentage >= 40) return 'green';
                break;
            default:
                return 'gray';
        }
    };

    const generateFileDownload = async (status) => {
        setIsDownloading(true);
        try {
            const res = await exportGrouOneCampaign({ status }, id); 

            if (res?.data?.file) {
                const a = document.createElement("a");
                a.href = `${process.env.REACT_APP_API_URL}/${res.data.file}`;
                a.download = "";
                a.click();
    
                Toastify("downloadOk", "success");
            } else {
                Toastify("downloadNotOk", "error");
            }

            setIsDownloading(false);
        } catch (error) {
            Toastify("downloadNotOk", "error");
            setIsDownloading(false);
        }
    };
    
    const generateGroups = async (status) => {
        setIsGeneratingGroup(true); 
        try {
            if (status === "spam" || status === "failed") {
                await blacklistedGroupCampaign({ status}, id);
                Toastify("createGroupsWithBlacklist", "success");
            } else {
                await generateGroupsCampaign({ status }, id);
                Toastify("createGroups", "success");
            }
            setIsGeneratingGroup(false);
        } catch (error) {
            Toastify("createGroupsFailed", "error");
            setIsGeneratingGroup(false);
        } 
    };

    const calculRoi = (click, valueClick = 0, budget) => {
        const revenueEstime = click * valueClick;
        const resultatNet = revenueEstime - budget;
        const roi = (resultatNet / budget) * 100;
        return roi;
    };

    const columns = [
		{ field: "firstName", headerName: t('contact.table.firstName'),  flex: 1, editable: true },
		{ field: "lastName", headerName: t('contact.table.lastName'), flex: 1, editable: true },
		{ field: "email", headerName: t('contact.table.email'), flex: 1, editable: true },
		{ field: "phone", headerName: t('contact.table.phone'), flex: 1, editable: true },
    ];
    
    return (
        <div className="one-campaign">

            <div className="backpage">
                <img src={iconArrowLeft} alt="icon-arrow-left" />
                <p><Link to="/campagnes">Retour</Link></p>
            </div>

            <h1 className="title-campaign"> Campagne : {oneCampaign?.title} </h1>
            <p className="nb-send-message">{oneCampaign?.nbSend } messages envoyés sur un groupe de {oneCampaign?.nbContactGroup} avec {oneCampaign?.nbContactGroup - oneCampaign?.nbSend} personnes blacklistés</p>

            <div className="engagements">

                <div className="title">
                    <h2>Engagements des contacts</h2>
                    {/*<button>
                        <img src={iconDownload} alt="icon-download" />
                        Télécharger le rapport en PNG
                    </button>*/}
                </div>

                <div className="kpi-content">
                    <div className="kpi">
                        {generateStatsData(oneCampaign).map((stat, index) => (
                            <StatsItem
                                key={index}
                                pourcent={stat.pourcent}
                                nbMessage={stat.nbMessage}
                                title={stat.title}
                                contentTooltip={stat.contentTooltip}
                                color={stat.color}
                            />
                        ))}
                    </div>
                    <div className="kpi-right">
                        <StatsGraph dataCampaign={oneCampaign} />

                        <div className="budget-meta">
                            <p>{oneCampaign?.ammountSpent} <span>€</span></p>
                            <p>Budget META de cette campagne</p>
                        </div>

                        <div className="roi-estimated">
                            <input 
                                type="number"
                                step="0.01"
                                placeholder="ROI estimé"
                                value={valueClick}
                                onChange={(e) => setValueClick(parseFloat(e.target.value) || 0)}
                            />
                            <p>
                                {calculRoi(oneCampaign?.nbClick, valueClick, oneCampaign?.ammountSpent).toFixed(1)}%
                                <span>€</span>
                            </p>
                            <p>ROI estimé</p>
                        </div>

                    </div>
                </div>

                {/*<StatsMessage />*/}
            </div>

            <div className="content-bottom">
                <div className="content-left">

                    <StatsClick dataClick={oneCampaign?.clickByButtons} />

                    <div className="contact-information">
                        <div className="title">
                            <h3>Informations des contacts</h3>
                            <div className="list-button">
                            <button onClick={() => generateFileDownload(filterContact)} disabled={isDownloading}>
                                Télécharger les contacts
                                {isDownloading && (
                                    <ClipLoader
                                        color={"#fff"}
                                        loading={isDownloading}
                                        size={20}
                                        cssOverride={{ marginLeft: 5 }}
                                    />
                                )}
                            </button>
                            <button onClick={() => generateGroups(filterContact)} disabled={isGeneratingGroup}>
                                {filterContact === "spam" || filterContact === "failed" 
                                    ? "Blacklister cette liste de contacts"
                                    : `Créer un groupe d'après "${filterContact}"`}
                                {isGeneratingGroup && (
                                    <ClipLoader
                                        color={"#fff"}
                                        loading={isGeneratingGroup}
                                        size={20}
                                        cssOverride={{ marginLeft: 5 }}
                                    />
                                )}
                            </button>
                            </div>
                        </div>

                        <div className="list-contact">
                            <div className="filter">
                                <button className={filterContact === "send" ? "actif" : ""} onClick={() => setFilterContact("send")}>Envois <span> {oneCampaign?.nbSend} </span></button>
                                <button className={filterContact === "delivered" ? "actif" : ""} onClick={() => setFilterContact("delivered")}>Délivrés <span>{oneCampaign?.nbDelivered}</span></button>
                                <button className={filterContact === "notView" ? "actif" : ""} onClick={() => setFilterContact("notView")}>Non lus <span>{oneCampaign?.nbNotView}</span></button>
                                <button className={filterContact === "view" ? "actif" : ""} onClick={() => setFilterContact("view")}>Lus <span>{oneCampaign?.nbView}</span></button>
                                <button className={filterContact === "clics" ? "actif" : ""} onClick={() => setFilterContact("clics")}>Clics <span>{oneCampaign?.nbClick}</span></button>
                                <button className={filterContact === "failed" ? "actif" : ""} onClick={() => setFilterContact("failed")}>échoués <span>{oneCampaign?.nbFail}</span></button>
                                <button className={filterContact === "spam" ? "actif" : ""} onClick={() => setFilterContact("spam")}>blacklist suggérée <span>{oneCampaign?.nbSpam}</span></button>
                            </div>
                        </div>

                        <div className="table">
                            <DataGridPro 
                                autoHeight 
                                rows={oneCampaignTable.data} 
                                columns={columns} 
                                pageSize={20} 
                                className="table" 
                                loading={oneCampaignTable.loading} 
                                pagination
                                pageSizeOptions={[20, 50, 100]}
                            />
                        </div>

                    </div>
                </div>
                <div className="content-right">
                    <WhatsAppExampleCampaign template={template} />
                </div>
            </div>

        </div>
    )
}