import styles from './integration.module.scss';
import logoZapier from '../../../assets/images/integration/logo-zapier.png';
import iconPlayVideo from '../../../assets/images/icons/icon-play-video.svg';

import { Link, useSearchParams } from "react-router-dom";

import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";

import { zapierAuthorizeAuth } from '../../../store/index.service';

export const Integration = () => {
    const [searchParams] = useSearchParams();

    const data = {
        clientId: searchParams.get('client_id'),
        state: searchParams.get('state'),
        responseType: searchParams.get('response_type'),
        redirectUri: searchParams.get('redirect_uri')
    }

    if (data.clientId && data.state && data.responseType && data.redirectUri) {
        zapierAuthorizeAuth(data).then(res => {
            if (res.status === 200)
                window.location.href = res.data;
        });

    }

    return (
        <div className={styles.integration}>
            <NavigationAccount active="integration" />
            <div className={styles.content}>
                <div className={styles.listItemIntegration}>

                    <div className={styles.itemIntegration}>
                        <div className={styles.logo}>
                            <img src={logoZapier} alt="Logo Zapier" />
                        </div>

                        <div className={styles.info}>
                            <p>Connection avec zapier</p>
                        </div>

                        <div className={styles.button}>
                            <Link className={styles.btnConnect} target="_blank" to="https://zapier.com/developer/public-invite/208174/f43ed6d2485ebb75589ee7a3b97f69d2/">Se connecter</Link>
                            {/*<button className={styles.btnVideo}>
                                <img src={iconPlayVideo} alt="Icon play" />
                                Voir la video
                            </button>*/}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}