import { createContext, useState, useCallback } from "react";
import { set } from "react-hook-form";

export const MercureContext = createContext();

export const MercureProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);
	const [nbNotif, setNbNotif] = useState(0);
	const [messages, setMessages] = useState([]);
	const [nbMessage, setNbMessage] = useState(0);
	const [subscribedTopics, setSubscribedTopics] = useState(new Set());
  
	const subscribeToTopic = useCallback((topic, onMessage) => {
		if (subscribedTopics.has(topic)) return;

		const url = new URL(process.env.REACT_APP_MERCURE_HUB);
		url.searchParams.append("topic", topic);
		const eventSource = new EventSource(url);

		eventSource.onmessage = (event) => {
			const data = JSON.parse(event.data);
			onMessage?.(data);
		};
  
		setSubscribedTopics((prev) => new Set([...prev, topic]));
  
		return () => {
			eventSource.close();
			setSubscribedTopics((prev) => {
				const newTopics = new Set(prev);
				newTopics.delete(topic);
				return newTopics;
			});
		};
	}, [subscribedTopics]);

	return (
		<MercureContext.Provider value={{ 
			notifications, 
			nbNotif, setNbNotif,
			messages, nbMessage,
			subscribeToTopic,
		}}>
			{children}
		</MercureContext.Provider>
	);
};
