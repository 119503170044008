import { Link } from "react-router-dom";
import styles from './Button.module.scss';

const buttonVariants = {
    full: styles.full,
    outlined: styles.outlined,
    textOnly: styles.textOnly
};

const colorVariants = {
    degradPurple: styles.degradPurple,
    purple: styles.purple,
    red: styles.red
}

export const Button = ({ variant = "full", color = "degradPurple", onClick = () => { }, to = "", content }) => {
    const styleButton = `${styles.buttonBase} ${buttonVariants[variant]} ${colorVariants[color]}`;
    if (to) {
        return (
            <Link to={to} className={styleButton}>
                {content}
            </Link>
        );
    }
    return (
        <button onClick={onClick} className={styleButton}>
            {content}
        </button>
    );
};
